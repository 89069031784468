import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Bickendorf: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Bickendorf,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Bickendorf"
          points="396,534 375,572 365,576 371,582 349,594 351,534 331,514 340,498 368,510  "
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 353.2856 538.4804)"
          textRendering="geometricPrecision"
        >
          <tspan x="0" y="0">
            Bicken-{' '}
          </tspan>
          <tspan x="0" y="14.4">
            dorf
          </tspan>
        </text>
      </g>
    </>
  );
});
